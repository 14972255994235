import request from '@/utils/request.js'

export function getList(params){
    return request({
        url:'/api/role/listPage',
        method:'get',
        params
    });
}

// 删除

export function del(id){
    return request({
        method:'delete',
        url:`/api/role/${id}`
    });
}
// 新增

export function add(data){
    return request({
        method:'post',
        url:'/api/role',
        data:{roleName:data}
    })
}

// 编辑初始化
export function updataInit(id){
    return request({
        url:`/api/role/updateInit/${id}`,
        method:'get'
    });
}
// 修改
export function update(data){
    return request({
        method:'put',
        url:'/api/role',
        data
    });
}

//分配权限初始化
export function giveMenuInit(params){
    return request({
        url:'/api/role/giveMenu/init',
        method:'get',
        params
    });
}

// 分配
export function allotMenu(data){
    return request({
        method:'post',
        url:'/api/role/giveMenu',
        data
    });
}